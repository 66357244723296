@import '../styles/mixins';

.MuiDialog-root {
  .MuiDialogContent-root {
    min-width: 0;

    .MuiTypography-root {
      padding: 0;
    }
  }
}

.MuiAppBar-root {
  position: absolute;
  z-index: 1001;
  background: none;
  box-shadow: none;
  @include themify($themes) {
    color: themed('primaryText');
  }

  .breadcrumbs {
    margin-top: 10px;
    padding: 0 10px;
    font-size: pxToRem(12px);
  }

  &.static {
    position: static;
  }

  &.filled {
    @include themify($themes) {
      background: themed('backgroundColor');
    }
  }

  h1 {
    margin-top: 0;
    padding: 0 10px;
    font-size: pxToRem(18px);
  }
}

.opened {
  .swagger .inner .breadcrumbs,
  .MuiAppBar-root {
    padding: 0;
  }
}

.main-content {
  position: relative;

  .inner {
    padding: 0 10px;
  }
}

.menu-trigger {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background: $color_neutral_1;
  border-radius: 0;
  color: $color_black;
  padding: 14px;

  &:hover {
    background: $color_neutral_1;
  }

  svg {
    font-size: 28px;
  }
}

.sidebar {
  position: fixed;
  z-index: 999;

  &--right {
    width: 380px;
  }

  &--left {
    left: 0;
    top: 0;
    height: 100vh;
    width: 380px;

    .sidebar--header {
      width: 100%;
      display: inline-flex;
      padding: 8px 24px 8px 24px;
    }
  }

  &--bottom {
    bottom: 0;
    left: 64px;
    right: 0;
    z-index: 1300;
    background: $color_white;
  }

  .outbounds-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color_white;
    font-size: pxToRem(14px);
    font-weight: bold;
    padding: 15px 40px;

    @include themify($themes) {
      background: themed('backgroundColor');
      color: themed('primaryColor');
    }
  }
}

.sidebar--menu {
  position: fixed;
  left: 0;
  width: 68px;
  z-index: 1004;
  top: 0;
  background: $color_primary_teal_green;
  height: 100vh;

  .b-home {
    width: 100%;
    margin: auto;
    svg {
      width: 38px;
      height: 38px;
    }
  }

  .inner-container {
    min-width: 0;
  }

  svg,
  .MuiListItemText-root > span {
    color: #a7b5b2 !important;
  }

  .MuiButtonBase-root {
    &:hover {
      svg,
      .MuiListItemText-root > span {
        color: $color_white !important;
      }
    }
    &.Mui-selected {
      svg,
      .MuiListItemText-root > span {
        color: $color_tertiary_citron !important;
      }
    }
  }
}

.user-menu--right {
  display: flex;
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
  border-radius: 4px;
  align-items: center;

  .MuiButtonBase-root {
    z-index: 999;
    height: 32px;
    width: 32px;
    svg {
      font-size: 22px;
      &.icon--filters {
        font-size: 19px;
      }
    }
  }
}

.head--right {
  .user-menu--right {
    position: relative;
    top: -6px;
    right: 10px;
  }
}

.page {
  margin-left: 68px;
  position: relative;
}

.main-content {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;

  .MuiToolbar-root {
    min-height: 40px;
    margin-top: 10px;
    padding: 0 10px !important;
  }
}
.theme-default,
.theme-color-blind {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;

  .view-map.left-drawer .main-content {
    left: 0 !important;
  }

  .page {
    &.opened {
      .swagger-ui .info h2.title {
        margin-left: 0;
      }
    }

    &.page--home {
      &.left-drawer {
        .viewport-container {
          left: 390px;
        }

        .main-content {
          left: 380px;
          right: 0;
        }

        .sidebar--bottom {
          left: 448px;
        }
      }

      &.right-drawer {
        .viewport-container {
          left: 10px;
          right: 390px;
        }

        .main-content,
        .sidebar--bottom {
          right: 380px;
          left: 0;
        }
      }

      &.left-drawer &.opened,
      &.right-drawer.left-drawer {
        .viewport-container {
          left: 390px;
          right: 390px;
        }

        .main-content {
          left: 380px;
          right: 380px;
        }

        .sidebar--bottom {
          left: 448px;
        }
      }
    }

    &.opened .view-map.left-drawer .main-content {
      left: 380px !important;
    }
  }
}
