@import 'styles/mixins';
@import 'styles/toast';

body {
  margin: 0;
  font-family: 'Manrope', 'Helvetica', 'Arial', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
}

.leaflet-container {
  font-family: 'Manrope', 'Helvetica', 'Arial', sans-serif !important;

  .popper--legend .MuiPaper-root {
    border: none !important;
  }

  @include themify($themes) {
    .leaflet-layer {
      filter: themed('mapFilter');
    }
    .leaflet-control {
      & > button {
        border: none;
        outline: none;
        padding: 0;
        min-width: 0;
        min-height: 0;
        margin: 0;
        width: 32px;
        height: 32px;
        font-size: 14px;
        box-shadow: 0px 8px 10px 0px #0000001a;
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .leaflet-control-zoom {
      border: none;
      outline: none;
      box-shadow: 0px 8px 10px 0px #0000001a;
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out {
        width: 32px;
        height: 32px;
        user-select: none;
        background-color: themed('backgroundColor');
        color: themed('primaryText');
        border: none;
        &:hover {
          background-color: themed('primaryHoverColor');
        }
      }
    }
  }
}

.viewport_options,
.viewport_hometimes {
  margin: 0;
}
.viewport-container {
  margin-top: 0;
  position: absolute;
  top: 60px;
  left: 10px;

  &.ht {
    right: 10px;
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  @include themify($themes) {
    background-color: themed('primaryHoverColor');
    &-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba($color_black, 0.3);
      background-color: themed('secondaryColor');
    }
  }
}

// #style-2::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba($color_black, 0.3);
//   border-radius: 10px;
//   background-color: #f5f5f5;
// }

/* .leaflet-tooltip-pane > div {
  background-color: red;
  border: 5px solid $color_black;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
} */

.MuiFormHelperText-root {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.MuiTypography,
.mui {
  &-head1 {
    @include head1;
  }
}

.run-assignment {
  .MuiOutlinedInput-root {
    margin: 10px;
    width: calc(100% - 20px);
  }

  .MuiPaper-root {
    min-width: 160px;
  }
}

.select--multiple {
  font-size: pxToRem(14px);
  .MuiButton-text,
  .MuiTypography-root {
    font-size: pxToRem(14px);
  }

  li {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    margin: 10px;
    width: calc(100% - 20px);
  }
}

.MuiPopover-root {
  .MuiOutlinedInput-root,
  .MuiMenuItem-root {
    font-size: pxToRem(14px);
  }
}

.select--radio {
  font-size: pxToRem(14px);

  .MuiButton-text,
  .MuiTypography-root {
    font-size: pxToRem(14px);
  }

  li {
    padding: 5px 10px;
    line-height: 28px;
  }

  .MuiOutlinedInput-root {
    margin: 10px;
    width: calc(100% - 20px);
  }
}

.form--reject-assignment,
.form--possible-assignment {
  .MuiChip-root {
    color: $color_white !important;
  }
}

.select--filter {
  .MuiSelect-select {
    line-height: 22px;
    height: 20px;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    font-size: pxToRem(14px);
  }
}

.MuiFormControlLabel-root .MuiTypography-root {
  font-size: pxToRem(14px);
}

.MuiPaper-root.MuiDrawer-paper {
  border: none;
  box-shadow: none;
}

.MuiChip-root {
  user-select: none;
  margin-top: 2px;
  margin-bottom: 2px;
  color: $color_black;

  span {
    padding: 1px 5px 0 5px;
  }

  &.Driving {
    background: $color_lime_4;
    border: solid 1px $color_lime_4;
    font-size: pxToRem(12px);
    line-height: pxToRem(21px);
    font-weight: 500;
  }

  &.OffDuty {
    background: $color_neutral_4;
    border: solid 1px $color_neutral_6;
    font-size: pxToRem(12px);
    line-height: pxToRem(21px);
    font-weight: 500;
  }

  &.OnDuty {
    background: $color_yellow_4;
    border: solid 1px $color_yellow_4;
    font-size: pxToRem(12px);
    line-height: pxToRem(21px);
    font-weight: 500;
  }

  &.Scheduled {
    background: $color_yellow_4;
    border: solid 1px $color_yellow_4;
    font-size: pxToRem(12px);
    line-height: pxToRem(21px);
    font-weight: 500;
  }

  &.Sleeper {
    background: $color_yellow_4;
    border: solid 1px $color_yellow_4;
    font-size: pxToRem(12px);
    line-height: pxToRem(21px);
    font-weight: 500;
  }

  &.business {
    background: $color_white;
    border: solid 1px $color_neutral_6;
    font-size: pxToRem(12px);
    line-height: pxToRem(21px);
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 2px;

    &.Enru {
      border-color: $color_neutral_9;
      color: $color_blue_6;
      background: $color_blue_1;
    }

    &.Salem {
      border-color: $color_yellow_6;
      color: $color_neutral_9;
      background: $color_yellow_1;
    }
  }
}

.MuiChip-root {
  .MuiChip-label {
    font-size: pxToRem(12px) !important;
    line-height: pxToRem(18px);
    font-weight: 600;
  }
}

.progress {
  max-width: 16px;
  min-width: 16px;
  overflow: hidden;
  height: 140px;

  .bar {
    height: 140px;
    width: 8px;
    border-radius: 5px;
    background: $color_lime_4;
    position: relative;

    overflow: hidden;

    .inner {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 8px;
      background: $color_lime_2;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &.high {
    .bar {
      background: rgba($color_lime_2, 0.9);

      .inner {
        background:
          linear-gradient(135deg, $color_lime_4 25%, transparent 25%) -6px 0,
          linear-gradient(-135deg, $color_lime_4 25%, transparent 25%) -6px 0,
          linear-gradient(45deg, $color_lime_4 25%, transparent 25%),
          linear-gradient(-45deg, $color_lime_4 25%, $color_lime_3 25%);
        background-size: 12px 12px;
        background-position:
          -8px 8px,
          -8px 8px,
          -2px 8px,
          -2px 8px;
      }
    }
  }

  &.medium {
    .bar {
      background: rgba($color_yellow_2, 0.9);

      .inner {
        background: repeating-linear-gradient(
          -45deg,
          $color_yellow_4,
          $color_yellow_4 6px,
          $color_yellow_3 6px,
          $color_yellow_3 12px
        );
      }
    }
  }

  &.low {
    .bar {
      background: rgba($color_red_2, 0.9);

      .inner {
        background: repeating-linear-gradient(
          45deg,
          $color_red_4,
          $color_red_4 6px,
          $color_red_3 6px,
          $color_red_3 12px
        );
      }
    }
  }
}

.leaflet {
  &-control,
  &-pane,
  &-top,
  &-bottom {
    z-index: 0 !important;
  }
}

.leaflet-popup-content {
  margin: 0;
}

.MuiInputBase-root {
  padding-left: 5px;

  &.MuiOutlinedInput-root input {
    padding: 8px 5px;
    font-size: 14px;
  }
}

.route-loading {
  background: #f5f5f5;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.cmp-loading {
  width: 100%;
  padding: 5px;
}

.poi {
  .icon {
    width: 33px;
    min-width: 33px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 33px;
    border: solid 2px $color_orange_4;
    border-radius: 50%;
    line-height: 48px;
    text-align: center;
    color: $color_orange_4;
    background-color: $color_orange_1;
  }

  &-Origin .icon {
    color: $color_lime_4;
    border-color: $color_lime_4;
    background-color: $color_lime_1;
  }

  &-Stop .icon {
    color: $color_yellow_4;
    border-color: $color_yellow_4;
    background-color: $color_yellow_1;
  }

  //&-Destination .icon {}

  &-HomeTimeStart .icon,
  &-HomeTimeEnd .icon {
    color: $color_pink_4;
    border-color: $color_pink_4;
    background-color: $color_pink_1;
  }

  &.past .icon {
    color: $color_neutral_6;
    border-color: $color_neutral_6;
    background-color: $color_neutral_1;
  }
}

.line-separator {
  border-top: solid 1px #ccc;
}

.MuiPopover-root {
  z-index: 1400;
}

.popover {
  &--layers,
  &--profile {
    &.MuiPopover-root {
      .MuiMenu-list {
        padding: 0;

        li {
          font-size: pxToRem(14px);
        }
      }

      & > .MuiPaper-root {
        position: fixed;
        margin-top: 12px;
        z-index: 1500;
      }
    }
  }

  &--profile.MuiPopover-root > .MuiPaper-root {
    margin-left: 6px;
  }

  &--layers.MuiPopover-root > .MuiPaper-root {
    margin-left: 32px;
  }

  &--notifications,
  &--manual-assignment {
    &.MuiPopover-root {
      & > .MuiPaper-root {
        position: fixed;
        left: auto !important;
        top: 60px !important;
        right: 10px !important;
        z-index: 1500;
        padding: 16px;
        overflow: visible;

        .container {
          margin-top: 5px;
          overflow: hidden;
          overflow-y: auto;
          padding-right: 10px;
        }
      }
    }
  }

  &--notifications.MuiPopover-root {
    & > .MuiPaper-root {
      bottom: 85px;

      .container {
        max-height: calc(100vh - 240px);
        height: calc(100vh - 240px);
      }
    }
  }

  &--manual-assignment.MuiPopover-root {
    & > .MuiPaper-root {
      // bottom: auto !important;
      // height: auto;
      // max-height: calc(100vh - 100px);
      // max-width: 374px;
      .container {
        .MuiTextField-root {
          margin-bottom: 16px;
        }
      }

      .MuiDialogActions-root {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
      }

      label {
        margin-top: -4px;
      }
    }
  }
}

.theme-color-blind {
  .fleet-list .info .driver-info > div label {
    color: $color_white;
  }

  .MuiAccordion-root {
    svg {
      fill: $color_white;
    }
  }

  .popover {
    &--notifications.MuiPopover-root {
      .notification {
        .MuiButtonBase-root,
        .MuiTypography-root {
          color: $color_white;
        }
        b,
        h6 {
          color: $color_blind_primary !important;
        }
      }
    }
  }
}

.tooltip-error {
  .hgh {
    color: $color_red_5;
    font-weight: bold;
  }
}

.load-type {
  @include themify($themes) {
    fill: themed('primaryText');
  }
}

.menu-items {
  .MuiMenuItem-root {
    font-size: pxToRem(14px);
  }
}

.theme-color-blind {
  .truck-assigments-table,
  .manifests-table {
    .MuiTableSortLabel-root {
      @include themify($themes) {
        color: themed('primaryText');
      }
    }
    .MuiChip-root {
      @include themify($themes) {
        border-color: themed('primaryText');
      }
      svg {
        @include themify($themes) {
          color: themed('primaryText');
        }
      }
    }
  }

  .tooltip-error {
    .MuiTooltip-tooltip {
      background: $color_black;
      color: $color_blind_primary;
      border-color: $color_blind_primary;

      .hgh {
        color: $color_white;
      }
    }
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background: $color_black;
    color: $color_blind_primary;

    label {
      color: $color_white !important;
    }
  }

  .MuiCheckbox-colorPrimary .MuiSvgIcon-root {
    color: $color_white;
  }

  .MuiToggleButtonGroup-root {
    .MuiButtonBase-root {
      color: $color_blind_primary;
    }
  }

  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 1px;
    }
  }
  .MuiAlert-standardInfo {
    color: $color_blind_primary;

    svg {
      color: $color_white;
    }
  }

  .MuiPopover-root .MuiList-root {
    .MuiBox-root {
      background: $color_black;
    }
  }

  .Mui-disabled {
    color: $color_blind_primary;
  }

  .MuiInputBase-root {
    border: solid 1px $color_blind_primary;

    svg {
      color: $color_blind_primary;
    }
  }

  .leaflet-popup-tip {
    background: $color_black !important;
  }

  .viewport_options .mrkr.truck,
  .leaflet-marker-icon.mrkr.truck {
    &.utilization {
      &-high,
      &-low,
      &-medium {
        .content .icon span {
          color: $color_blind_primary;
        }
      }
    }
  }

  .flatpickr-calendar {
    .flatpickr-months {
      .flatpickr-prev-month,
      .flatpickr-next-month {
        fill: $color_black;

        &:hover svg {
          fill: $color_neutral_8;
        }
      }

      .flatpickr-month,
      .flatpickr-monthDropdown-months,
      .flatpickr-monthDropdown-month {
        background-color: $color_blind_primary;
        color: $color_black;
      }

      .numInputWrapper {
        .numInput {
          color: $color_black;
        }

        .arrowUp {
          &:after {
            border-bottom-color: $color_black;
          }
        }

        .arrowDown {
          &:after {
            border-top-color: $color_black;
          }
        }
      }
    }

    .flatpickr-innerContainer {
      background-color: $color_black;

      .flatpickr-weekdays,
      .flatpickr-weekday {
        background-color: $color_blind_primary;
        color: $color_black;
      }

      .dayContainer {
        .flatpickr-day {
          color: $color_white;

          &:hover {
            color: $color_neutral_8;
          }

          &.prevMonthDay,
          &.nextMonthDay {
            color: $color_neutral_8;
          }

          &.inRange {
            color: $color_black;
          }

          &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
          &.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
          &.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
            box-shadow: -10px 0 0 $color_blind_primary !important;
          }

          &.startRange,
          &.endRange,
          &.selected {
            border-color: $color_blind_primary;
            background-color: $color_blind_primary;
            color: $color_black;
          }
        }
      }
    }

    .flatpickr-time {
      background-color: $color_blind_primary;
      .numInputWrapper,
      .flatpickr-am-pm,
      input {
        &:hover,
        &:focus {
          background-color: rgba($color_black, 0.05);
        }
      }
    }
  }
}

.flatpickr-calendar {
  &:after,
  &:before {
    display: none !important;
  }
}

.utilization-select {
  border-radius: 5px;

  .MuiToggleButton-root {
    border: none !important;
    border-radius: 0 5px 5px 0 !important;

    &:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    &:nth-child(2) {
      border-radius: 0 !important;
    }
  }
}

.all-filters {
  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 1px;
    }
  }

  .utilization-select {
    border: solid 1px $color_primary;
    .MuiButtonBase-root {
      border-right: solid 1px $color_primary !important;

      &:last-child {
        border: none !important;
      }
    }
  }

  .MuiAccordionSummary-root {
    min-height: 38px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }

  .must-start-by {
    .MuiOutlinedInput-notchedOutline {
      border: none;
      box-shadow: none;
      outline: none;
    }
    .MuiInputBase-root {
      @include themify($themes) {
        color: themed('primaryText');
        background-color: themed('backgroundColor');
      }
    }
  }
}

.MuiButton-outlined {
  @include themify($themes) {
    color: themed('primaryText');
    background-color: themed('backgroundColor');

    &.Mui-selected,
    &.active {
      color: themed('secondaryText');
      background: themed('primaryText');
    }

    &:hover {
      color: themed('primaryText');
      background-color: themed('primaryHoverColor');
    }
  }
}

.MuiButton-contained {
  @include themify($themes) {
    &:hover {
      color: themed('primaryText');
      background-color: themed('primaryHoverColor');
      border-color: themed('primaryText');
    }
  }
}

.Mui-active .MuiTableSortLabel-icon {
  @include themify($themes) {
    color: themed('primaryText');
  }
}

.fleet-header {
  .MuiToggleButton-root,
  .MuiButton-root {
    min-width: 0;

    &.filter {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
}

.side-menu {
  background: $color_primary_teal_green;
  color: $color_white;
  border-radius: 0;

  .inner-container {
    overflow: hidden;

    .MuiListItemButton-root {
      &.Mui-selected {
        background: rgba($color_white, 0.08);
      }

      &.logout .MuiListItemText-root .MuiTypography-root {
        line-height: 18px;
      }
    }

    & > .MuiList-root {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      font-size: pxToRem(14px);
      font-weight: bold;

      @media screen and (max-height: 236px) {
        .MuiDivider-root {
          height: auto;
        }
      }

      .MuiListItemButton-root {
        &:hover {
          background: inherit;
        }
      }

      & > .MuiListItemButton-root {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        text-align: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .MuiListItemIcon-root {
          min-width: 0;
          margin: auto;
        }
        .MuiListItemText-root {
          width: 100%;

          span {
            padding: 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: pxToRem(12px);
          }
        }
      }

      .MuiListItemText-root .MuiTypography-root {
        color: $color_white;
        font-weight: bold;
        font-size: pxToRem(14px);
      }

      .MuiListItemIcon-root {
        color: #fff;
        //color: $color_tertiary_citron;
        min-width: 30px;
      }

      .MuiList-root {
        color: $color_green_2;

        .MuiListItemButton-root {
          padding-left: 5px;
        }
      }
    }
  }

  .user-info,
  .build-info {
    color: $color_secondary_steel_teal;
    font-weight: normal;
  }

  .build-info {
    font-size: pxToRem(8px);
  }

  .b-close {
    background: $color_white;
    border-radius: 0;
    color: $color_black;
    padding: 14px;

    svg {
      font-size: 28px;
    }
  }

  .head {
    gap: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.sidebar--bottom {
  margin: 0 10px;
}

.sidebar--bottom > .MuiBox-root {
  @include themify($themes) {
    color: themed('secondaryText');
    background: themed('primaryText');
  }
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  font-size: pxToRem(13px);
  line-height: 24px;
}

.MuiFormControl-root {
  .MuiChip-root {
    border-radius: 4px;
    span {
      font-size: pxToRem(13px);
      font-weight: normal;
      padding: 10px;
    }
  }
}

.MuiPopover-root.MuiModal-root {
  .MuiButtonBase-root {
    min-height: 34px;
  }
}

.b-truck {
  padding-left: 8px !important;
  min-width: 120px;
  justify-content: space-between;
}

.MuiTableCell-root,
.MuiTableCell-root .MuiStack-root,
.MuiTableCell-root > .MuiBox-root {
  .MuiTypography-head2,
  .MuiButtonBase-root {
    td {
      @include themify($themes) {
        color: themed('secondaryText');
      }
    }
  }

  .mnfst {
    @include themify($themes) {
      color: themed('primaryText');
    }
  }

  @include themify($themes) {
    color: themed('primaryColor');
    background: themed('backgroundColor');
  }
}
